import React, { useState, useEffect } from 'react';
import './App.css';

// 数字をランダムな数式で表現する関数
const representNumber = (number: number): string => {
  let result = '';
  let sum = 0;
  while (sum !== number) {
    const rand = Math.floor(Math.random() * 10); // 0から9までのランダムな数
    if (sum + rand <= number) {
      sum += rand;
      result += result ? ` + ${rand}` : `${rand}`;
    } else if (sum - rand >= 0 && result) { // 最初の数字が既にある場合のみ引き算を許可
      sum -= rand;
      result += ` - ${rand}`;
    }
  }
  return result;
};

const TimeRepresentation: React.FC = () => {
  const [hoursRepresentation, setHoursRepresentation] = useState<string>('');
  const [minutesRepresentation, setMinutesRepresentation] = useState<string>('');
  const [secondsRepresentation, setSecondsRepresentation] = useState<string>('');

  // 現在の時刻を取得し、数式で表現する関数
  // useEffectの外に移動しました
  const updateTime = (): void => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    setHoursRepresentation(representNumber(hours));
    setMinutesRepresentation(representNumber(minutes));
    setSecondsRepresentation(representNumber(seconds));
  };

  useEffect(() => {
    updateTime(); // 初期表示時に一度実行
    const interval = setInterval(updateTime, 1000); // 1秒ごとに更新
    return () => clearInterval(interval); // コンポーネントのアンマウント時にインターバルをクリア
  }, []); // 依存配列は空のままです
  
  return (
    <div>
      <h2>現在時刻:</h2>
      <div className="time-display">
        <div className="time-unit">{hoursRepresentation}時</div>
        <div className="time-unit">{minutesRepresentation}分</div>
        <div className="time-unit">{secondsRepresentation}秒</div>
      </div>
    </div>
  );
};

export default TimeRepresentation;
